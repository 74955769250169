.fold{
  position: relative;

  min-height: 100vh;

  background-color: var(--blue);

  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    h1{
      display: inline-block;

      font-weight: 300;
      text-transform: uppercase;
    }

    .subheadline{
      margin-bottom: var(--spacing8);

      font-size: var(--fontSize4);
      font-style: italic;
    }

    .buttons{
      display: grid;
      gap: var(--spacing6);
      align-items: stretch;

      >*{
        flex-grow: 1;
        flex-shrink: 0;
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
}
